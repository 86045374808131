import React, { useState, useEffect } from 'react';
import './ShirtIntro.css';
import { createIntegrationError, updateUserProfile } from '../../../../graphql/mutations';
import { API, graphqlOperation } from '@aws-amplify/api';
import {Analytics} from 'aws-amplify';

function ShirtIntro({userProfile, setCustomUserProfile, setAppStage}) {

    const [subStage, setSubStage] = useState('intro');
    const [isPriorMember, setIsPriorMember] = useState(false);
    const [tempPassword, setTempPassword] = useState('');

    // subStage path for user with existing fanMaker profile
    // intro -> claim -> matchFound -> unlock -> unlockComplete -> saveProfile

    // subStage path for user with no matched profile
    // intro -> claim -> getConsent -> signUp -> signUpComplete -> saveProfile
    
    useEffect(() => {
        
        if(subStage === 'claim'){
            claimAction();

        } else if(subStage === 'unlock'){
            unlockAction();
            
        } else if(subStage === 'signUp'){
            signUpAction();
            
        } else if(subStage === 'saveProfile'){
            saveProfileAction(false);

        } else if(subStage === 'saveProfileNewLead'){
            saveProfileAction(true);

        }
    }, [subStage]);

    // user takes first basic step to claim prize
    const claimAction = () => {

        console.log('user clicked claim');
        Analytics.record({ name: 'ramsrewards', attributes: {action: 'fanClaimed'}});


        const requestBody = {
            body: { email: userProfile.email },
            headers: { 'Content-Type': 'application/json' },
        };
      
        // calls fnFindUsers lambda function
        API.post('fanMakerYup', '/users', requestBody).then((response) => {

            if(response?.statusCode === 200){
                console.log('user profile exists in fanmaker');
                Analytics.record({ name: 'ramsrewards', attributes: {action: 'apiMatch'}});

                // save fanMakerId for later reference
                setIsPriorMember(true);

                // go to match found screen
                setSubStage('matchFound');

            } else if(response?.statusCode === 202){
                console.log('user profile does not exist in fanmaker');
                Analytics.record({ name: 'ramsrewards', attributes: {action: 'apiNewProfile'}});


                // go to screen to get consent to create profile
                setSubStage('getConsent');
            }
        }).catch(error => {
            logError(error);
        });
        
    };

    // matched user takes action to unlock reward
    const unlockAction = () => {

        console.log('matched user unlocking reward');
        Analytics.record({ name: 'ramsrewards', attributes: {action: 'fanUnlocked'}});

        const requestBody = {
            body: { 
                email: userProfile.email,
                priorMember: isPriorMember,
            },
            headers: { 'Content-Type': 'application/json' },
        };
        
        // calls fnCreateUser lambda function
        API.post('fanMakerYup', '/points', requestBody).then((response) => {
            
            if(response?.statusCode === 200){

                console.log('matched user has unlocked reward');

                setSubStage('unlockComplete');
            } 
        }).catch(error => {
            logError(error);
        });
    };

    // unmatched user takes action to create profile and unlock reward
    const signUpAction = () => {

        console.log('unmatched user signing up');
        Analytics.record({ name: 'ramsrewards', attributes: {action: 'fanSignUp'}});

        const requestBody = {
            body: { 
                email: userProfile.email 
            },
            headers: { 'Content-Type': 'application/json' },
        };
        
        // calls fnCreateUser lambda function
        API.post('fanMakerYup', '/points', requestBody).then((response) => {

            if(response?.statusCode === 200){
                console.log('user created profile with points');

                setTempPassword(response.password);

                // update profile with new id
                setSubStage('signUpComplete');
            } 
        }).catch(error => {
            logError(error);
        });
    };

    // updates RallyChat profile with fanMakerId + metrics
    const saveProfileAction = (newLead) => {

        // add id, plus mark them as claimed
        const input = {
            id: userProfile.id,
            fanMakerId: userProfile.email,
            newRamsRewardsLead: newLead,
        };

        API.graphql(graphqlOperation(updateUserProfile, { input })).then((profileResp) => {
            if (profileResp?.data?.updateUserProfile) {
                console.log("fan profile updated in RallyChat");

                // save profile to local storage
                localStorage.setItem('rally_profile_wEmail', JSON.stringify(profileResp?.data?.updateUserProfile));

                // set the custom profile based on creating it from API
                // will cause the app to reset and main chat to load
                setCustomUserProfile(profileResp?.data?.updateUserProfile);
            }
        }).catch(error => {
            logError(error);
        });
    };

    const logError = (error) => {

        // return to main chat
        setAppStage('chat');

        const input = {
            detail: JSON.stringify(error.response) + ' '+JSON.stringify(error),
        };

        API.graphql(graphqlOperation(createIntegrationError, { input })).then((resp) => {
            // success , do nothing
        }).catch(error => {
            console.log(error);
        });
    };

    return (
        <div className='rrMain'>

            {/* shows up for all users. Claim - will call fanmaker to detect a profile */}
            {subStage === 'intro' && (
                <div className='rrSub'>
                    <div className='rrHeader'>FREE TSHIRT GIVEAWAY!</div>
                    <img src={`reward/rallyShirt.png`} className='img-fluid gint-logo rrReward' alt="card logo"/>
                    <button className='rrBtnAction' onClick={() => setSubStage('claim')}>Claim</button>
                    <button className='rrBtnBack' onClick={() => setAppStage('chat')}>back to chat</button>
                </div>
            )}

            {/* shows up for matched users. Unlock - will call fanmaker to assign points */}
            {subStage === 'matchFound' && (
                <div className='rrSub'>
                    <div className='rrHeader'>VCU Ram Rewards!</div>
                    <div className='rrText'>RallyChat has partnered with the VCU Ram Rewards program, and your email {userProfile.email} matched with an exiting RamRewards account!</div>
                    <div className='rrText'>Click to unlock this free, exclusive tshirt in the RamsRewards prize options of the official VCU Athletics app.</div>
                    <img src={`reward/logowreward.png`} className='' alt="card logo"/>
                    <button className='rrBtnAction' onClick={() => setSubStage('unlock')}>Unlock</button>
                    <button className='rrBtnBack' onClick={() => setAppStage('chat')}>back to chat</button>
                </div>
            )}  

            {/* shows up for match user after they unlock reward. back to chat - will update RallyChat profiles */}
            {subStage === 'unlockComplete' && (
                <div className='rrSub'>
                    <div className='rrHeader'>Your Prize is Unlocked!</div>
                    <div className='rrText'>Check the VCU Rams Rewards prize options in the offical VCU Athletics app.</div>
                    <img src={`reward/rallyShirt.png`} className='img-fluid gint-logo rrReward' alt="card logo"/>
                    <button className='rrBtnBack' onClick={() => setSubStage('saveProfile')}>back to chat</button>
                </div>
            )}

            {/* shows up for unmatched users. Sign Up - will call fanmaker to create profile AND assign points */}
            {(subStage === 'getConsent') && (
                <div className='rrSub'>
                    <div className='rrHeader'>Ram Rewards!</div>
                    <div className='rrText'>RallyChat has partnered with the VCU RamRewards program to provide a free, exclusive tshirt in the official VCU Athletics app.</div>
                    <div className='rrText'>Click below to create a free RamRewards account for {userProfile.email} and claim your swag.</div>
                    <img src={`reward/logowreward.png`} className='' alt="card logo"/>
                    <button className='rrBtnAction' onClick={() => setSubStage('signUp')}>Sign Up!</button>
                    <button className='rrBtnBack' onClick={() => setAppStage('chat')}>back to chat</button>
                </div>
            )}  

            {/* shows up for unmatched user after they click signup. back to chat - will update RallyChat profiles */}
            {subStage === 'signUpComplete' && (
                <div className='rrSub'>
                    <div className='rrHeader'>Your Prize is Unlocked!</div>
                    <div className='rrText'>You should recieve a VCU Athletics RamsRewards email shortly with full instructions on accessing your profile and claiming your tshirt. Use your email {userProfile.email} and temporary password {tempPassword}</div>
                    <img src={`reward/rallyShirt.png`} className='img-fluid gint-logo rrReward' alt="card logo"/>
                    <button className='rrBtnBack' onClick={() => setSubStage('saveProfileNewLead')}>back to chat</button>
                </div>
            )}

            {(['signUp', 'claim', 'unlock', 'saveProfile'].includes(subStage)) && (
                <div className='rrSpinnerOverlay'>
                    <div className='rrSpinner'></div>
                </div>
            )}  
            
        </div>
    );

    
}
export default ShirtIntro;