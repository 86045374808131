import { useEffect, useState } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { onCreateMessage } from '../../../../graphql/subscriptions';
import StatFloat from './StatFloat';

function StatBar() {

    const [incomingStatMsgs, setIncomingStatMsgs] = useState([]);
    const [displayMsgs, setDisplayMsgs] = useState([]);
    const [statCheck, setStatCheck] = useState(0);

    // use for setting up listeners
    useEffect(() => {
        const subMessages = API
            .graphql(graphqlOperation(onCreateMessage, {filter: {type:{eq:'STAT'}}}))
            .subscribe({
                next: (event) => {

                    // get data from call
                    const msgData = event.value.data.onCreateMessage;
                    setIncomingStatMsgs(prev => [...prev, msgData]);
                }
        });

        return () => {
            subMessages.unsubscribe();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // this effect checks the new incoming stats every six seconds to see if there is a new one to display
    // this keeps them from displaying on top of each other as they come in
    useEffect(() => {
        const timer = setTimeout(() => {

            // move the oldest stat message to the display queue
            if(incomingStatMsgs.length > 0){
                let tempAr = incomingStatMsgs;
                setDisplayMsgs(prev => [...prev, tempAr.shift()]);
                setIncomingStatMsgs(tempAr);
            }

            // loop this effect
            setStatCheck(prev => prev+1);
        }, 6000);

        return () => clearTimeout(timer);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statCheck]);

    return (
        <div style={{ position: 'relative' }}>
            {displayMsgs.map((dispMsg) => (
                <StatFloat key={dispMsg.id} content={dispMsg.body} />
            ))}
        </div>
    );
}
export default StatBar;
