
import { useEffect, useRef } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { onCreateMessage } from '../../../../graphql/subscriptions';

function MessageHandler({userProfile, btnQueue1, btnQueue2, btnQueue3, btnQueue4}) {
    
    const destId = 'btn1';
    const destIdRef = useRef(destId);

    // use for setting up listeners
    useEffect(() => {

        if(userProfile){

            const subMessages = API.graphql(graphqlOperation(onCreateMessage))
                .subscribe({
                    next: (event) => {

                        const msgData = event.value.data.onCreateMessage;

                        // can't figure out server-side filtering syntax - do that later
                        // profile info not explicitly returned to be filtered
                        // can't do OR for type
                        // we can filter by channel when that become
                        if(
                            ( 
                                msgData.type === 'USER' && 
                                msgData.userProfile.id !== userProfile.id && 
                                !msgData.userProfile.shadowBanned &&
                                !msgData.politicalDiscourse &&
                                !msgData.sexualContent &&
                                !msgData.racismSexismHomophobia &&
                                !msgData.violentSpeech
                            ) || msgData.type === 'BOT' 
                        ){

                                // set the next message destination - rotates through - split even
                            if(destIdRef.current === 'btn1'){
                                btnQueue1(msgData);
                                destIdRef.current = 'btn2';
                            } else if(destIdRef.current === 'btn2') {
                                btnQueue2(msgData);
                                destIdRef.current = 'btn3';
                            }
                            else if(destIdRef.current === 'btn3'){
                                btnQueue3(msgData);
                                destIdRef.current = 'btn4';
                            }
                            else if(destIdRef.current === 'btn4'){
                                btnQueue4(msgData);
                                destIdRef.current = 'btn1';
                            }
                        }
                       
                    }
            });

            return () => {
                subMessages.unsubscribe();
            };
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile])          
}
export default MessageHandler;
