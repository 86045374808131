import {Analytics} from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { TouchableWithoutFeedback, Keyboard, TextInput} from 'react-native';
import { API, graphqlOperation } from '@aws-amplify/api';
import { createMessage } from '../../../../graphql/mutations';
import { createIntegrationError } from '../../../../graphql/mutations';

import './MessageInput.css';

function MessageInput({userProfile, atInfo, setAtInfo}) {

    const [placeText, setPlaceText] = useState('new comment');
    const [textValue, setTextValue] = useState('');

    // used to reset placeholder text
    useEffect(() => {

        const confirmationClear = setTimeout(() => {

            // reset placeholder text
            setPlaceText('new comment');
        }, 2000);

        return (() => clearTimeout(confirmationClear));

    }, [placeText]); 

    // used to set @ text
    useEffect(() => {

        if(atInfo){
            
            // set display text
            setTextValue(atInfo);

            // clear @ info so it's always picked up, even user responsed to the same person again
            setAtInfo('');
        }

    }, [atInfo, setAtInfo]); 

    const handleInputChange = async(event) => {

        if(event.key === 'Enter') {

            event.preventDefault();
            event.stopPropagation();        
            
            handleCreateMessage();
        }
    };

    const handleCreateMessage = async() => {

        // ensure text is not empty, all whitespace
        if(textValue && textValue.trim()){

            // always perform these cleanup steps 
            setTextValue('');  // clear out text box
            setPlaceText('message sent'); // set placeholder
            Keyboard.dismiss(); // close the keyboard automatically

            try {

                // create body for modration API
                const moderationBody = {
                    body: { msg: textValue.trim() },
                    headers: { 'Content-Type': 'application/json' },
                };
            
                // calls moderation lambda function
                let modResponse = await API.post('fanMakerYup', '/moderation', moderationBody);

                console.log(modResponse);

                // if it succeedes, process the metadata and submit to chat
                if(modResponse?.statusCode === 200){

                    const input = {
                        channel: '1',
                        messageUserProfileId: userProfile.id,
                        type: 'USER',
                        body: modResponse.msgMetadata.modified_message,
                        unmaskedBody: textValue.trim(),
                        maskedContent: modResponse.msgMetadata.flag_profane_or_offensive,
                        sentimentScore: modResponse.msgMetadata.sentiment_analysis,
                        relevancyScore: modResponse.msgMetadata.relevancy_analysis,
                        politicalDiscourse: modResponse.msgMetadata.flag_political_discourse,
                        sexualContent: modResponse.msgMetadata.flag_sexual_content,
                        racismSexismHomophobia: modResponse.msgMetadata.flag_racism_sexism_homophobia,
                        violentSpeech: modResponse.msgMetadata.flag_violent_speech,
                    };

                    console.log(input);

                    let creatMsgResponse = await API.graphql(graphqlOperation(createMessage, {input} ));

                    // log event
                    Analytics.record({ name: 'msgCreated', attributes: { user: userProfile.id }});

                } else {
                    logError(JSON.stringify(modResponse));
                    console.warn(modResponse);
                }

            } catch (error) {
                logError(JSON.stringify(error));
                console.warn(error);
            }

            
        }
    }

    const logError = (error) => {

        const input = {
            detail: JSON.stringify(error.response) + ' '+JSON.stringify(error),
        };

        API.graphql(graphqlOperation(createIntegrationError, { input })).then((resp) => {
            // success , do nothing
        }).catch(error => {
            console.log(error);
        });
    };


    return (
        <div className='app-text-ready'>
            <TouchableWithoutFeedback accessible={false}>
                <TextInput 
                    keyboardType='default' 
                    placeholder={placeText} 
                    onKeyPress={handleInputChange} 
                    value={textValue}
                    onChangeText = {setTextValue}/>
            </TouchableWithoutFeedback>
            <div onClick={handleCreateMessage}>&#8594;</div>
        </div>
    );
}
export default MessageInput;