import React from 'react';
import { motion } from 'framer-motion';
import './EmojiFloat.css';

import data from '@emoji-mart/data'
import { init } from 'emoji-mart'

init({ data })

function EmojiFloat({content, xPosition}) {

    return (

        // animate float up
        <motion.div 
            key={content.id}
            animate={{ y: -230 }}
            transition={{ duration: 3.5, ease: 'easeInOut' }}
            className='app-float'
            style={{left: xPosition+'%'}}>

            {/* animate wobbles */}
            <motion.div 
                animate={{ delay: .5, x: 1, y:1, scale:[1,1.2], rotate:5 }}
                transition={{ duration: 2, type: 'spring', damping: 0 }}>
                
                {/* component from emoji-mart package - passing unicode as object parameters didn't work */}
                <em-emoji id={content.id} skin={content.skinTone} size="1em"></em-emoji>
            </motion.div>

        </motion.div>
    );
}
export default EmojiFloat;