import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { createMessage } from '../../../graphql/mutations';
import {Analytics} from 'aws-amplify';

import './MemoryGame.css';
import MemoryCard from './MemoryCard';

function MemoryGame({userProfile, gameType, setGameStage}) {

    const [cards, setCards] = useState([]);
    const [cardStateChange, setCardStateChange] = useState([]);
    const [openCards, setOpenCards] = useState([]);

    const [freezeClicks, setFreezeClicks] = useState(false);

    useEffect(() => {

        // card set
        const defaulCards = [
            {key:'mgc1', imgName:'2', value:'vsAlpha', flipped: false},
            {key:'mgc2', imgName:'2', value:'vsAlpha', flipped: false},
            {key:'mgc3', imgName:'3', value:'vsBeta', flipped: false},
            {key:'mgc4', imgName:'3', value:'vsBeta', flipped: false},
            {key:'mgc5', imgName:'4', value:'vsCharlie', flipped: false},
            {key:'mgc6', imgName:'4', value:'vsCharlie', flipped: false},
            {key:'mgc7', imgName:'5', value:'vsDelta', flipped: false},
            {key:'mgc8', imgName:'5', value:'vsDelta', flipped: false},
        ]

        // randomize the cards
        const randomCards = defaulCards.sort((a, b) => Math.random() - 0.5);
        setCards(randomCards);

        // close this game stage after 45 seconds   
        const closeStage = setTimeout(() => {
            setGameStage(3);
        }, 45000);

        return (() => clearTimeout(closeStage));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile])

    useEffect(() => {
    
        // if there are any incoming card state changes
        // are clicks allowed right now?
        if(!freezeClicks && cardStateChange.length > 0){

            // get oldest state change
            const stateChange = cardStateChange.pop();

            // don't proceed if this card is already flipped
            if(!stateChange.flipped){

                // no other cards are open
                if(openCards.length === 0){

                    // flip just this card
                    flipCard(stateChange.key);

                    // add to open cards
                    setOpenCards(x => [...x, stateChange]);

                } else if(openCards.length === 1){

                    // is the other open card a match? 
                    if(openCards[0].value === stateChange.value){

                        // flip just this card
                        flipCard(stateChange.key);

                        // clear open cards
                        setOpenCards([]);
                    } else {

                        // show the card for .5 seconds
                        flipCard(stateChange.key);

                        // set freeze on clicks - prevent mashing buttons
                        setFreezeClicks(true);

                        // then hide it
                        setTimeout(() => {

                            // flip both cards back
                            let newCards = cards.map(elm => (elm.key === stateChange.key ? {...elm, flipped:false} : elm))
                            newCards = newCards.map(elm => (elm.key === openCards[0].key ? {...elm, flipped:false} : elm))
                            setCards(newCards);

                            // clear open cards
                            setOpenCards([]);

                            // allow card flips again
                            setFreezeClicks(false);
                        }, 750);
                    }
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardStateChange])

    // listed to card changes to see if puzzel is solved
    useEffect(() => {

        // don't run on inital load
        if(cards.length > 0){

            // reduce to find if all are solved
            let allSolved = cards.reduce((accumulator, card) => accumulator && card.flipped, true);
        
            // gg go next ez lol
            if(allSolved){

                // advance to next stage
                setGameStage(3);

                // log to server
                const input = {
                    channel: '1',
                    messageUserProfileId: userProfile.id,
                    type: 'GAME',
                    body: 'Beat Memory'
                };
            
                // post to backend service
                try {
                    API.graphql(graphqlOperation(createMessage, { input }))
                } catch (error) {
                    console.warn(error);
                }

                // analytics
                Analytics.record({ name: 'gameCompleted', attributes: {ad: gameType?.sponsor}});
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cards])

    // flip a card
    const flipCard = (key) => {
        let newCards = cards.map(elm => (elm.key === key ? {...elm, flipped:true} : elm))
        setCards(newCards);
    };

    return (
        <div className='gm-stage-2'>
            <h1>Click to Match</h1>
            <div className='gm-grid'>
                {cards.map((card) => (
                    <MemoryCard key={card.key} cardInfo={card} sponsor={gameType?.sponsor} setCardStateChange={setCardStateChange} />
                ))}
            </div>
        </div>

    );
}
export default MemoryGame;