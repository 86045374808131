import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { onCreateReaction, onCreateChannelAlert } from '../../../graphql/subscriptions';

import './ProfileMetrics.css';


function ProfileMetrics({userProfile}) {

    const [votes, setVotes] = useState([]);

    useEffect(() => {

        let tempVotes = [];

        // pull past score from local storage and populate votes
        for(let x = 0; x < localStorage.getItem('upVotes'); x++){tempVotes.push('UP');}
        for(let x = 0; x < localStorage.getItem('downVotes'); x++){tempVotes.push('DOWN');}

        // add to general variable
        setVotes(tempVotes);

        // filter on just score alerts
        const alertFilter = {
            type: { eq: 'CLEAR_SCORES' }
        };

        // listen to stage changes coming from backend
        const subAlert = API.graphql(graphqlOperation(onCreateChannelAlert, {filter: alertFilter}))
        .subscribe({next: (event) => {
            
            // clear out votes
            localStorage.removeItem('upVotes');
            localStorage.removeItem('downVotes');
            setVotes([]);
        }});
    
        return () => {
            subAlert.unsubscribe();
        };

    }, [userProfile]);

    // set up voting listener
    useEffect(() => {

        // filter on just reactions to this user
        const reactionFilter = {
            messageUserProfileId: { eq: userProfile.id }
        };

        // listen to all incoming reactions
        const subReactions = API.graphql(graphqlOperation(onCreateReaction, {filter: reactionFilter}))
        .subscribe({
            next: (event) => {
                const msgData = event.value.data.onCreateReaction;

                // temp array so all logic works on updated values (react stuff)
                const tempVotes = [...votes, msgData.type];

                // add to vote count
                setVotes(tempVotes);  
                
                // save to local storage
                localStorage.setItem('upVotes',tempVotes.filter(x => x === 'UP').length);
                localStorage.setItem('downVotes',tempVotes.filter(x => x === 'DOWN').length);
            }
        });

        return () => {
            subReactions.unsubscribe();
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [votes])

    return (
        <div className='metrics'>
            <div>{userProfile.name}</div>
            <div className='score-arrow-up'>
                &#9650; {votes.filter(x => x === 'UP').length}
            </div>
            <div className='score-arrow-down'>
                &#9660; {votes.filter(x => x === 'DOWN').length}
            </div>
        </div>
    );
}
export default ProfileMetrics;