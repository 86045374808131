import React, { useState } from "react";

import MessageButton from "./messaging/MessageButton";
import EmojiWheel from "./reactions/EmojiWheel";
import HighlightWindow from "./HighlightWindow";
import MessageInput from "./messaging/MessageInput";
import MessageHandler from "./messaging/MessageHandler";
import FriendHandler from "./FriendHandler";
import StatBar from "./stats/StatBar";
import ScoreLine from "./stats/ScoreLine"

import "@aws-amplify/ui-react/styles.css";
import "./ChatMain.css";

function ChatMain({customUserProfile, autoEmotes}) {
    // queue of incoming messages from service
    // set here to distribute to different button elements
    // default welcome message
    const [btnQueue1, setBtnQueue1] = useState({id:'faker', type:'INIT', body:'Welcome'});
    const [btnQueue2, setBtnQueue2] = useState({id:'faker', type:'INIT', body:'To'});
    const [btnQueue3, setBtnQueue3] = useState({id:'faker', type:'INIT', body:'Rally'});
    const [btnQueue4, setBtnQueue4] = useState({id:'faker', type:'INIT', body:'Chat'});
    const [friendList, setFriendList] = useState([]);
  
    // for passing @ info to message input
    const [atInfo, setAtInfo] = useState('');

    return (
        <div className="container-fluid d-flex flex-column chat-main">
        <div className="row app-header">
                <img
                src="/white-grey.png"
                className="img-fluid"
                alt="VCU"
                />
                <div className="col"><ScoreLine/></div>
            </div>
            <div className="row h-25">
                <HighlightWindow userProfile={customUserProfile} autoEmotes={autoEmotes} />
            </div>
            <div className="row stat-bar">
                <StatBar />
            </div>
            <div className="row flex-grow-1">
                <MessageHandler
                userProfile={customUserProfile}
                btnQueue1={setBtnQueue1}
                btnQueue2={setBtnQueue2}
                btnQueue3={setBtnQueue3}
                btnQueue4={setBtnQueue4}
                />

                <FriendHandler
                userProfile={customUserProfile}
                friendList={friendList}
                setFriendList={setFriendList}
                />

                <div className="col h-100">
                <MessageButton
                    userProfile={customUserProfile}
                    friendList={friendList}
                    newMessage={btnQueue1}
                    setAtInfo={setAtInfo}
                ></MessageButton>
                </div>
                <div className="col">
                <MessageButton
                    userProfile={customUserProfile}
                    friendList={friendList}
                    newMessage={btnQueue2}
                    setAtInfo={setAtInfo}
                ></MessageButton>
                </div>
            </div>
            <div className="row app-emoji-row">
                <EmojiWheel userProfile={customUserProfile} />
            </div>
            <div className="row flex-grow-1">
                <div className="col h-100">
                <MessageButton
                    userProfile={customUserProfile}
                    friendList={friendList}
                    newMessage={btnQueue3}
                    setAtInfo={setAtInfo}
                ></MessageButton>
                </div>
                <div className="col">
                <MessageButton
                    userProfile={customUserProfile}
                    friendList={friendList}
                    newMessage={btnQueue4}
                    setAtInfo={setAtInfo}
                ></MessageButton>
                </div>
            </div>
            <div className="row app-text">
                <MessageInput userProfile={customUserProfile} atInfo={atInfo} setAtInfo={setAtInfo}/>
            </div>
        </div>
    );
  }
  
  export default ChatMain;
  