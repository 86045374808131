/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      channel
      body
      unmaskedBody
      type
      reactions {
        items {
          id
          type
          messageUserProfileId
          createdAt
          updatedAt
          messageReactionsId
          reactionUserProfileId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sentimentScore
      relevancyScore
      politicalDiscourse
      sexualContent
      racismSexismHomophobia
      violentSpeech
      maskedContent
      updatedAt
      messageUserProfileId
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      channel
      body
      unmaskedBody
      type
      reactions {
        items {
          id
          type
          messageUserProfileId
          createdAt
          updatedAt
          messageReactionsId
          reactionUserProfileId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sentimentScore
      relevancyScore
      politicalDiscourse
      sexualContent
      racismSexismHomophobia
      violentSpeech
      maskedContent
      updatedAt
      messageUserProfileId
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      id
      channel
      body
      unmaskedBody
      type
      reactions {
        items {
          id
          type
          messageUserProfileId
          createdAt
          updatedAt
          messageReactionsId
          reactionUserProfileId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sentimentScore
      relevancyScore
      politicalDiscourse
      sexualContent
      racismSexismHomophobia
      violentSpeech
      maskedContent
      updatedAt
      messageUserProfileId
      __typename
    }
  }
`;
export const onCreateEmote = /* GraphQL */ `
  subscription OnCreateEmote($filter: ModelSubscriptionEmoteFilterInput) {
    onCreateEmote(filter: $filter) {
      id
      channel
      emojiId
      skinTone
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      emoteUserProfileId
      __typename
    }
  }
`;
export const onUpdateEmote = /* GraphQL */ `
  subscription OnUpdateEmote($filter: ModelSubscriptionEmoteFilterInput) {
    onUpdateEmote(filter: $filter) {
      id
      channel
      emojiId
      skinTone
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      emoteUserProfileId
      __typename
    }
  }
`;
export const onDeleteEmote = /* GraphQL */ `
  subscription OnDeleteEmote($filter: ModelSubscriptionEmoteFilterInput) {
    onDeleteEmote(filter: $filter) {
      id
      channel
      emojiId
      skinTone
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      emoteUserProfileId
      __typename
    }
  }
`;
export const onCreateReaction = /* GraphQL */ `
  subscription OnCreateReaction($filter: ModelSubscriptionReactionFilterInput) {
    onCreateReaction(filter: $filter) {
      id
      message {
        id
        channel
        body
        unmaskedBody
        type
        reactions {
          nextToken
          __typename
        }
        createdAt
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        sentimentScore
        relevancyScore
        politicalDiscourse
        sexualContent
        racismSexismHomophobia
        violentSpeech
        maskedContent
        updatedAt
        messageUserProfileId
        __typename
      }
      type
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      messageUserProfileId
      createdAt
      updatedAt
      messageReactionsId
      reactionUserProfileId
      __typename
    }
  }
`;
export const onUpdateReaction = /* GraphQL */ `
  subscription OnUpdateReaction($filter: ModelSubscriptionReactionFilterInput) {
    onUpdateReaction(filter: $filter) {
      id
      message {
        id
        channel
        body
        unmaskedBody
        type
        reactions {
          nextToken
          __typename
        }
        createdAt
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        sentimentScore
        relevancyScore
        politicalDiscourse
        sexualContent
        racismSexismHomophobia
        violentSpeech
        maskedContent
        updatedAt
        messageUserProfileId
        __typename
      }
      type
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      messageUserProfileId
      createdAt
      updatedAt
      messageReactionsId
      reactionUserProfileId
      __typename
    }
  }
`;
export const onDeleteReaction = /* GraphQL */ `
  subscription OnDeleteReaction($filter: ModelSubscriptionReactionFilterInput) {
    onDeleteReaction(filter: $filter) {
      id
      message {
        id
        channel
        body
        unmaskedBody
        type
        reactions {
          nextToken
          __typename
        }
        createdAt
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        sentimentScore
        relevancyScore
        politicalDiscourse
        sexualContent
        racismSexismHomophobia
        violentSpeech
        maskedContent
        updatedAt
        messageUserProfileId
        __typename
      }
      type
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      messageUserProfileId
      createdAt
      updatedAt
      messageReactionsId
      reactionUserProfileId
      __typename
    }
  }
`;
export const onCreateDynamicSettings = /* GraphQL */ `
  subscription OnCreateDynamicSettings(
    $filter: ModelSubscriptionDynamicSettingsFilterInput
  ) {
    onCreateDynamicSettings(filter: $filter) {
      env
      msgFrequency
      partyLive
      emotes
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDynamicSettings = /* GraphQL */ `
  subscription OnUpdateDynamicSettings(
    $filter: ModelSubscriptionDynamicSettingsFilterInput
  ) {
    onUpdateDynamicSettings(filter: $filter) {
      env
      msgFrequency
      partyLive
      emotes
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDynamicSettings = /* GraphQL */ `
  subscription OnDeleteDynamicSettings(
    $filter: ModelSubscriptionDynamicSettingsFilterInput
  ) {
    onDeleteDynamicSettings(filter: $filter) {
      env
      msgFrequency
      partyLive
      emotes
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateChannelAlert = /* GraphQL */ `
  subscription OnCreateChannelAlert(
    $filter: ModelSubscriptionChannelAlertFilterInput
  ) {
    onCreateChannelAlert(filter: $filter) {
      id
      channel
      type
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChannelAlert = /* GraphQL */ `
  subscription OnUpdateChannelAlert(
    $filter: ModelSubscriptionChannelAlertFilterInput
  ) {
    onUpdateChannelAlert(filter: $filter) {
      id
      channel
      type
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChannelAlert = /* GraphQL */ `
  subscription OnDeleteChannelAlert(
    $filter: ModelSubscriptionChannelAlertFilterInput
  ) {
    onDeleteChannelAlert(filter: $filter) {
      id
      channel
      type
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserProfile = /* GraphQL */ `
  subscription OnCreateUserProfile(
    $filter: ModelSubscriptionUserProfileFilterInput
  ) {
    onCreateUserProfile(filter: $filter) {
      id
      cognitoUserId
      name
      fullName
      email
      phone
      shadowBanned
      newRamsRewardsLead
      fanMakerId
      friends {
        items {
          id
          createdAt
          updatedAt
          userProfileFriendsId
          userFriendUserId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserProfile = /* GraphQL */ `
  subscription OnUpdateUserProfile(
    $filter: ModelSubscriptionUserProfileFilterInput
  ) {
    onUpdateUserProfile(filter: $filter) {
      id
      cognitoUserId
      name
      fullName
      email
      phone
      shadowBanned
      newRamsRewardsLead
      fanMakerId
      friends {
        items {
          id
          createdAt
          updatedAt
          userProfileFriendsId
          userFriendUserId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserProfile = /* GraphQL */ `
  subscription OnDeleteUserProfile(
    $filter: ModelSubscriptionUserProfileFilterInput
  ) {
    onDeleteUserProfile(filter: $filter) {
      id
      cognitoUserId
      name
      fullName
      email
      phone
      shadowBanned
      newRamsRewardsLead
      fanMakerId
      friends {
        items {
          id
          createdAt
          updatedAt
          userProfileFriendsId
          userFriendUserId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserFriend = /* GraphQL */ `
  subscription OnCreateUserFriend(
    $filter: ModelSubscriptionUserFriendFilterInput
  ) {
    onCreateUserFriend(filter: $filter) {
      id
      user {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userProfileFriendsId
      userFriendUserId
      __typename
    }
  }
`;
export const onUpdateUserFriend = /* GraphQL */ `
  subscription OnUpdateUserFriend(
    $filter: ModelSubscriptionUserFriendFilterInput
  ) {
    onUpdateUserFriend(filter: $filter) {
      id
      user {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userProfileFriendsId
      userFriendUserId
      __typename
    }
  }
`;
export const onDeleteUserFriend = /* GraphQL */ `
  subscription OnDeleteUserFriend(
    $filter: ModelSubscriptionUserFriendFilterInput
  ) {
    onDeleteUserFriend(filter: $filter) {
      id
      user {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userProfileFriendsId
      userFriendUserId
      __typename
    }
  }
`;
export const onCreateIntegrationError = /* GraphQL */ `
  subscription OnCreateIntegrationError(
    $filter: ModelSubscriptionIntegrationErrorFilterInput
  ) {
    onCreateIntegrationError(filter: $filter) {
      id
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateIntegrationError = /* GraphQL */ `
  subscription OnUpdateIntegrationError(
    $filter: ModelSubscriptionIntegrationErrorFilterInput
  ) {
    onUpdateIntegrationError(filter: $filter) {
      id
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteIntegrationError = /* GraphQL */ `
  subscription OnDeleteIntegrationError(
    $filter: ModelSubscriptionIntegrationErrorFilterInput
  ) {
    onDeleteIntegrationError(filter: $filter) {
      id
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
