
import { useEffect } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { onCreateUserFriend, onDeleteUserFriend } from '../../../graphql/subscriptions';
import { listUserFriends } from '../../../graphql/queries';


function FriendHandler({userProfile, friendList, setFriendList}) {
    
    useEffect(() => {

        if(userProfile){

            // get all current friends
            API.graphql(graphqlOperation(listUserFriends))
            .then((response) => {
                const items = response.data.listUserFriends.items;
                if (items) {
                    setFriendList(items.filter(x => x.userProfileFriendsId === userProfile.id));
                }
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile])

    // set up listeners
    useEffect(() => {

        if(userProfile){

            // sub friends
            const subFriends = API
                .graphql(graphqlOperation(onCreateUserFriend))
                .subscribe({
                    next: (event) => {

                        const friendData = event.value.data.onCreateUserFriend;

                        // filter on just my friends
                        if(friendData && friendData.userProfileFriendsId === userProfile.id){

                            // feed into logic to display on the UI
                            setFriendList([...friendList, friendData]);
                        }
                    }
            });

            // sub delete friends
            const subDeleteFriends = API
                .graphql(graphqlOperation(onDeleteUserFriend))
                .subscribe({
                    next: (event) => {

                        const friendData = event.value.data.onDeleteUserFriend;

                        // filter on just my friends
                        if(friendData && friendData.userProfileFriendsId === userProfile.id){

                            // remove this friend from the list
                            setFriendList(friendList.filter(x => x.id !== friendData.id));
                        }
                    }
            });

            return () => {
                subFriends.unsubscribe();
                subDeleteFriends.unsubscribe();
            };
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [friendList, userProfile])
            
}
export default FriendHandler;
