import { useEffect, useState } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { onCreateMessage } from '../../../../graphql/subscriptions';

function ScoreLine() {

    const [score, setScore] = useState('Welcome!');

    // use for setting up listeners
    useEffect(() => {

        const subMessages = API
            .graphql(graphqlOperation(onCreateMessage, {filter: {type:{eq:'SCORE'}}}))
            .subscribe({
                next: (event) => {

                    // get data from call
                    const msgData = event.value.data.onCreateMessage;

                    // change score
                    setScore(msgData.body);
                }
        });

        return () => {
            subMessages.unsubscribe();
        };
    }, []);

    return (
        <div>{score}</div>
    );
}
export default ScoreLine;
