import React, { useEffect, useState } from 'react';
import {Analytics} from 'aws-amplify';

import { API, graphqlOperation } from '@aws-amplify/api';
import { createReaction, createUserFriend, deleteUserFriend } from '../../../../graphql/mutations';

import { motion } from 'framer-motion';
// import { faker } from '@faker-js/faker';
import './MessageButton.css';

import MsgPopup from './MsgPopup';

function MessageButton({userProfile, friendList, newMessage, setAtInfo}) {

    const [fontClass, setFontClass] = useState(null);
    const [friendClass, setFriendClass] = useState('');
    const [dispMessage, setDispMessage] = useState({id:'faker',type:'BOT', bodyTrunc:'Welcome!'});
    const [originXY, setOriginXY] = useState({x:0,y:0});
    const [dragXY, setDragXY] = useState({x:0,y:0});
    const [finalXY, setFinalXY] = useState({x:0,y:0});
    const [colorClass, setColorClass] = useState(null);
    const [userTypeClass, setUserTypeClass] = useState(null);
    const [dragMsgToggle, setDragMsgToggle] = useState('text');
    const [animationOptions, setAnimationOptions] = useState({scale: [0, 0]});
    const [lastAction, setLastAction] = useState(null);
    const [msgOverflow, setMsgOverflow] = useState(true);
    const [displayMe, setDisplayMe] = useState(true);
    const [userMessages, setUserMessages] = useState([]);
    const [botMessages, setBotMessages] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [isChef, setIsChef] = useState(false);


    // minimize / maximize
    const [popupOpen, setPopupOpen] = useState(false);


    // how far a message is dragged before it is considered an action
    const actionBuffer = 50;

    // how long messages stays up before disappearing
    var msgLife = 15000;

    // reset message username CSS when new message come in
    // or when new friend list updates come in
    // useEffect(() => {
        
    //     if(friendList.filter(x => x.userFriendUserId === dispMessage.userProfile.id).length){
    //         setFriendClass('msg-highlight-name');
    //     } else {
    //         setFriendClass('');
    //     }

    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [friendList, dispMessage]);


    useEffect(() => {

        // only load messages if there are messages to load
        // on init it will be dummy messages
        let animMe; 
        let bringMeBack;

        // show button
        if(displayMe){
            
            // set the next message
            setNextMessage();

            // scale in
            animMe = {scale: [0, 1.1, 1]};
            setColorClass('neutral');
            setDragMsgToggle('main');

            // set timeout to refresh call
            // TODO: isn't working on load for some reason
            bringMeBack = setTimeout(() => {

                // clear out top message
                setLastAction('reactDown');
                setDisplayMe(false);

            }, msgLife); // refresh message content
        } 
        // hide button with animations specific to action
        else {
            if(lastAction === 'reactUp' || lastAction === 'friendAdded'){
                animMe = {scale: [1, 1.5, 0]};
            } else if(lastAction === 'reactDown'){
                animMe = {scale: [1, 0]};
            } else {
                animMe = {scale: [1, 0]};
            }

            // set timeout to bring button back
            bringMeBack = setTimeout(() => {

                // clear out top message
                setDisplayMe(true);

            }, 1000); // bring back after wait
        }

        // set animation
        setAnimationOptions(animMe);
        
        return (() => clearTimeout(bringMeBack));
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayMe])

    //scaling message text size
    useEffect(() => {

        if (dispMessage.body && (typeof dispMessage.body === 'string' || dispMessage.body instanceof String)){
            // reference array - order is important
            // TODO : do this better
            const classMapping = [{l:30,f:'fs-6'},{l:10,f:'fs-4'},{l:0,f:'fs-2'}];
        
            // map to correct font size
            setFontClass(classMapping.find(x => x.l <= dispMessage.body.length).f);
        }

    }, [dispMessage])

    useEffect(() => {
        // add to internal array
        if(newMessage){

            // seperate out user messages from bots
            if(newMessage.type === 'USER'){
                setUserMessages((prevList) => [...prevList, newMessage]);
            } else if(newMessage.type === 'BOT'){
                setBotMessages((prevList) => [...prevList, newMessage]);
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMessage]);

    // called when message is released from drag
    // decide if it qualifies for an upvote
    useEffect(() => {
        
        if(finalXY.y && finalXY.y > (originXY.y+actionBuffer)){
            setLastAction('reactDown');
            setDisplayMe(false);
            sendReaction('DOWN');

            Analytics.record({ name: 'msgDown', attributes: { user: userProfile.id }});

        } else if(finalXY.y && finalXY.y <= (originXY.y-actionBuffer)){
            setLastAction('reactUp');
            setDisplayMe(false);
            sendReaction('UP');

            Analytics.record({ name: 'msgUP', attributes: { user: userProfile.id }});

        } else if(dragXY.x && dragXY.x > (originXY.x+actionBuffer)){
            setLastAction('friendAdded');
            setColorClass('neutral');
            setDragMsgToggle('main');
            
            if(dispMessage.userProfile){
                setAtInfo('@'+dispMessage.userProfile.name+' ');
            }
            
            // sendFriendUpdate(true);

            // Analytics.record({ name: 'friendAdded', attributes: { user: userProfile.id }});

        } else if(dragXY.x && dragXY.x <= (originXY.x-actionBuffer)){
            setLastAction('friendRemoved');
            setColorClass('neutral');
            setDragMsgToggle('main');
            // sendFriendUpdate(false);

            //  // log event
            //  Analytics.record({ name: 'friendRemoved', attributes: { user: userProfile.id }});
        }

    // we don't want to run this on originY changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalXY])

    // called when message is dragged
    // color button appropriately
    useEffect(() => {

        // remove user highlight
        setUserTypeClass('');

        if(dragXY.y && dragXY.y > (originXY.y+actionBuffer)){
            setColorClass('nope');
            setDragMsgToggle('arrow-down');
        } else if(dragXY.y && dragXY.y <= (originXY.y-actionBuffer)){
            setColorClass('promote');
            setDragMsgToggle('arrow-up');
        } else if(dragXY.x && dragXY.x > (originXY.x+actionBuffer)){
            setColorClass('friendAdded');
            setDragMsgToggle('arrow-right');
        } else if(dragXY.x && dragXY.x <= (originXY.x-actionBuffer)){
            setColorClass('friendRemoved');
            setDragMsgToggle('arrow-left');
        } else {
            setColorClass('neutral');
            setDragMsgToggle('main');
        }
    // we don't want to run this on originY changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragXY])

    const setNextMessage = () => {

        let nextMessage; 

        // default welcome message on first load
        if(newMessage && newMessage.type === 'INIT' && firstLoad){
            nextMessage = newMessage;
            setFirstLoad(false);
        } else {
            // default message
            nextMessage = {
                id: 'faker',
                body: 'loading...',
                // body: faker.lorem.words(20),
                // body: <img alt='' className='app-message-img' src="https://thumbs.gfycat.com/InsignificantVibrantAphid-max-1mb.gif" />,
                userProfile: {name:'macuser'}
            };
        }

        // default look for messages
        setUserTypeClass('');

        // pick from the right queue
        // show user over generated
        if(userMessages && userMessages.length > 0){
            nextMessage = userMessages.pop();
        } 
        else if(botMessages && botMessages.length > 0){
            nextMessage = botMessages.pop();
        }

        // if there is a message
        if(nextMessage && nextMessage.body){

            try {
                
                // truncate 
                nextMessage.bodyTrunc = nextMessage.body.slice(0,60);

                // default value 
                setMsgOverflow(false);

                // did it truncate part of the message?
                if(nextMessage.body.length > 60){
                    setMsgOverflow(true);
                    nextMessage.bodyTrunc += '...'
                }

                // if this is the HOST user, don't hide their comment 
                // highlight the message visually
                if(nextMessage.userProfile?.name?.toLowerCase() === 'chefnix'){
                    setIsChef(true);
                    msgLife = 1000000;
                    setUserTypeClass('msg-highlight');
                    setFriendClass('app-message-name-host');
                } else {
                    setIsChef(false);
                    setFriendClass('app-message-name');
                }

            } catch(error){
                // console.log('unable to display - '+newMessage.body);

                // if there was an error trying to clean the message, catch it and display nothing
                nextMessage = {
                    id: 'faker',
                    body: 'loading...',
                    bodyTrunc: 'loading...',
                    userProfile: {name:'macuser'}
                };
            }
        }

        setDispMessage(nextMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const sendReaction = async (type) => {

        // only vote real user messages
        if(dispMessage.id !== 'faker'){

            const input = {
                messageReactionsId: dispMessage.id,
                type: type,
                reactionUserProfileId: userProfile.id,
                messageUserProfileId: dispMessage.messageUserProfileId,
            };

            // post to backend service
            try {
                await API.graphql(graphqlOperation(createReaction, {input}));
            } catch (error) {
                console.warn(error);
            }
        }
    }

    // eslint-disable-next-line
    const sendFriendUpdate = async (added) => {

        // only add real user messages
        if(dispMessage.id !== 'faker'){

            // has this friendship already been added?
            const matchingFriends = friendList.filter(x => x.userFriendUserId === dispMessage.userProfile.id && x.userProfileFriendsId === userProfile.id)

            if(added && !matchingFriends.length) {

                // add new friend
                const input = {
                    userProfileFriendsId: userProfile.id,
                    userFriendUserId: dispMessage.userProfile.id
                };

                try {
                    await API.graphql(graphqlOperation(createUserFriend, {input}));
                } catch (error) {
                    console.warn(error);
                }
            } else if(!added && matchingFriends.length) {

                // remove friend
                const input = {
                    id : matchingFriends[0].id
                };

                try {
                    await API.graphql(graphqlOperation(deleteUserFriend, {input}));
                } catch (error) {
                    console.warn(error);
                }
            }
            
        }
    }

    return (
        <div className='app-msg-container'>
        <motion.div 
            drag
            dragConstraints={{ top: 10, bottom: 10, left: 10, right:10 }}
            whileDrag={{ scale: 0.8}}
            onDragStart={(event, info) => setOriginXY(info.point)}
            onDrag={(event, info) => setDragXY(info.point)}
            onDragEnd={(event, info) => setFinalXY(info.point)}
            animate={animationOptions}
            className={colorClass+' '+userTypeClass+' app-message'}>

            {dragMsgToggle==='main' && <div>

                {/* main text */}
                <div className={fontClass + ' app-message-text'}>
                    {dispMessage.bodyTrunc}
                </div>

                {/* user name */}
                {dispMessage.userProfile && dispMessage.userProfile.name !== 'macuser' && <div className={friendClass}>
                   - {dispMessage.userProfile.name} {isChef && <p>&nbsp;&#9819;</p>}
                </div>}

                {/* expand message button */}
                {msgOverflow && <div className='app-message-expand' onClick={() => setPopupOpen(true)}>...</div>}
            </div>}
            
            {dragMsgToggle==='arrow-up' && <div className='up-arrow'>&#9650;</div>}
            {dragMsgToggle==='arrow-down' && <div className='down-arrow'>&#9660;</div>}
            {dragMsgToggle==='arrow-left' && <div className='left-minus'>&#9866;</div>}
            {dragMsgToggle==='arrow-right' && <div className='right-plus'>@</div>}

        </motion.div>
        {/* has to be outside the motion div for position: fixed to work */}
        {popupOpen && <MsgPopup text={dispMessage.body} onClose={() =>  setPopupOpen(false)}/>}
        </div>
    );
}
export default MessageButton;
