import React, { useState } from 'react';
import {Analytics} from 'aws-amplify';
import { API, graphqlOperation } from '@aws-amplify/api';
import { createEmote } from '../../../../graphql/mutations';

import { motion } from 'framer-motion';
import './EmojiWheel.css';

import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { init } from 'emoji-mart'

init({ data })

function EmojiWheel({userProfile}) {

    const [originPoint, setOriginPoint] = useState(null);
    const [dragStarted, setDragStarted] = useState(false);
    const [animationOptions, setAnimationOptions] = useState({scale: [0,0]});
    const [cssSectionActive, setCssActiveSection] = useState('app-wheel-none-active');
    const [cssButtonActive, setCssButtonActive] = useState('');
    const [touchIndexRest, setTouchIndexReset] = useState(0);
    const [centerEmoji, setCenterEmoji] = useState({id: 'basketball', skinTone: '1'});
    const [showPicker, setShowPicker] = useState(false);

    const [idMapper, setIdMapper] = useState({
        center: {id: 'basketball', skinTone: '1'},
        top: {id: 'fire', skinTone: '1'},
        bottom: {id: 'ice_cube', skinTone: '1'},
        left: {id: 'spider', skinTone: '1'},
        right: {id: 'ram', skinTone: '1'},
    });
    
    // the handles starting the click
    const handleTouchStart = (event) => {

        // active css for button
        setCssButtonActive('app-emoji-button-active');

        // set drag started to false
        setDragStarted(false);
    }
    
    // basic cleanup to perform whether this is a click end or a drag end
    const handleTouchEnd = (event, info) => {

        // if this wasn't a drag, then it was a simple center click
        if(!dragStarted){
                
            // get the correct emoji id to pass
            const emoji = idMapper['center'];

            // fire event
            //document.dispatchEvent(new CustomEvent('emoji-selection', { detail : eId }));
            sendEmote(emoji);
        }

        // hide wheel
        setAnimationOptions({scale: [1,0]});

        // reset css
        setCssButtonActive('');
        setCssActiveSection('app-wheel-none-active');
        setTouchIndexReset((ct) => ct+1);
    }

    // show wheel immediately if the user starts a drag
    const handleDragBegin= (event, info) => {

        // set origin point for later referece
        setOriginPoint({x:info.point.x, y:info.point.y})

        // set flag for logic in click handler
        setDragStarted(true);

        // show wheel
        setAnimationOptions({scale: [0,1], rotate: [270, 360]});

        // set plus sign to show picker
        setCenterEmoji({id: 'heavy_plus_sign', skinTone:'1'});
    }

    // specific handling of the end of the drag selection
    const handleDragEnd = (event, info) => {

        // find where click ended
        const selection = calculateQuadrant(info.point);

        if(selection !== 'none'){

            // get specific emoji id to pass
            const emoji = idMapper[selection];

            // fire event
            //document.dispatchEvent(new CustomEvent('emoji-selection', { detail : eId }));
            sendEmote(emoji);

            // center the new emoji if they selected a wheel option
            if(selection !== 'center') {

                // reshuffle
                shuffleWheel(selection);
            } else {
                setShowPicker(true);
            }
        } else {

            // reset center emoji 
            setCenterEmoji(idMapper['center']);
        }
    }

    // handles when drag event is going on, to highlight specific sections
    const handleDrag = (event, info) => {

        if(originPoint) {

            // find where drag is 
            const selection = calculateQuadrant(info.point); 

            // determine where the drag ended
            setCssActiveSection('app-wheel-'+selection+'-active');
        }
    }

    // used to shuffle the emojis around the center and wheel as they are selected
    const shuffleWheel = (selection) => {

        // get original values
        const emojiNew = idMapper[selection];
        const emojiOrigin = idMapper['center'];

        // swap out emojis with new locations
        let tempIdMapper = idMapper;
        tempIdMapper[selection] = emojiOrigin;
        tempIdMapper['center'] = emojiNew;

        // set new array
        setIdMapper(tempIdMapper);

        // neede to trigger re-render - doesn't pick up on array/object change mabye?
        setCenterEmoji(emojiNew);
    }

    // common function for calculating where the drag is/ended
    const calculateQuadrant = (point) => {

        // get the offset from drag origin
        let trueOffsetX = point.x - originPoint.x;
        let trueOffsetY = originPoint.y - point.y;
        let quadrant = 'none';

        // determine where the drag ended
        if(trueOffsetX > 140 || trueOffsetY > 140 || trueOffsetX < -140 || trueOffsetY < -140){
            quadrant = 'none';
        }else if(trueOffsetY > 60){
            quadrant = 'top';
        } else if (trueOffsetY < -60) {
            quadrant = 'bottom';
        } else if (trueOffsetX > 60) {
            quadrant = 'right';
        } else if (trueOffsetX < -60) {
            quadrant = 'left';
        } else {
            quadrant = 'center';
        }

        return quadrant;
    }

    const sendEmote = async (emoji) => {

        const input = {
            channel: '1',
            emoteUserProfileId: userProfile.id,
            emojiId: emoji.id,
            skinTone: emoji.skinTone,
        };

        // post to backend service
        try {
            await API.graphql(graphqlOperation(createEmote, { input }))
        } catch (error) {
            console.warn(error);
        }

        // log event
        Analytics.record({ name: 'emojiSent', attributes: { user: userProfile.id }});
    }

    const handlePickerSelection = (pickerSelection) =>{
        
        // hide component
        setShowPicker(false);

        // convert object
        var newEmoji = {
            id: pickerSelection.id,
            skinTone: (pickerSelection.skin ? pickerSelection.skin : '1'),
        }

        setCenterEmoji(newEmoji);

        // swap out emojis with new locations
        // TODO: use spread operator
        let tempIdMapper = idMapper;
        tempIdMapper['center'] = newEmoji;
        setIdMapper(tempIdMapper);

        // fire event
        sendEmote(newEmoji);

        // log event
        // Analytics.record({ name: 'emojiPicker', attributes: { user: userProfile.id }});
    }


    return (
        <div className='app-wheel-container'>

            {/* transparent div used to detect click and drag on the wheel behind */}
            <motion.div 
                drag
                // we reset this element on every touch end to avoid the drag + fast double click bug
                key={'tr '+touchIndexRest} 
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onDragStart={handleDragBegin}
                onDrag={handleDrag}
                onDragEnd={handleDragEnd}
                className='app-wheel-draggable'
            ></motion.div>

            {/* the actual wheel - hidden unless user drags */}
            <motion.div 
                animate={animationOptions}
                transition={{ duration: 0.2 }}
                className={cssSectionActive+' app-emoji-wheel container-fluid d-flex flex-column'}>
                
                <div className='row flex-grow-1 app-wheel-section'>
                    <em-emoji id={idMapper['top'].id} skin={idMapper['top'].skinTone} size="1em"></em-emoji>
                </div>
                <div className='row flex-grow-1 app-wheel-section'>
                    <div className='col app-wheel-section'>
                        <em-emoji id={idMapper['left'].id} skin={idMapper['left'].skinTone} size="1em"></em-emoji>
                    </div>
                    <div className='col app-wheel-section'></div>
                    <div className='col app-wheel-section'>
                        <em-emoji id={idMapper['right'].id} skin={idMapper['right'].skinTone} size="1em"></em-emoji> 
                    </div>
                </div>
                <div className='row flex-grow-1 app-wheel-section'>
                    <em-emoji id={idMapper['bottom'].id} skin={idMapper['bottom'].skinTone} size="1em"></em-emoji>
                </div>
            </motion.div>

            {/* center button - always visible*/}
            <div className={'app-emoji-button '+cssButtonActive}><em-emoji id={centerEmoji.id} skin={centerEmoji.skinTone} size="1em"></em-emoji></div>
        
            {showPicker && <div className='app-emoji-picker'>
                <Picker data={data} categories={['people','nature','activity','objects']} onEmojiSelect={handlePickerSelection} onClickOutside={(e) => setShowPicker(false)}/>
            </div>}

        </div>
    );
}
export default EmojiWheel;