import React from 'react';
import { motion } from 'framer-motion';

import './StatFloat.css';

function StatFloat({content}) {

    return (
        <motion.div 
            initial={{ x: '100vw' }} // Start offscreen to the right
            animate={{ x: '-300vw' }}
            transition={{ duration: 9, ease: 'linear' }}
            className='stat-float'>

            {content}

        </motion.div>
    );
}
export default StatFloat;
