import React, { useState } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { createUserProfile } from '../../../graphql/mutations';
import { View, TextInput, TouchableOpacity, Text, StyleSheet, Keyboard } from 'react-native';
import "./IntroMain.css";

function IntroMain({setCustomUserProfile, partyLive}) {

    // setting for stepping through the app instructions
    const [stage, setStage] = useState(5);

    const [username, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [btnBackground, setBtnBackground] = useState('#E6A400');
    const [valMsg, setValMsg] = useState('');

    const handleSubmit = async (event) => {

        event.preventDefault();
        event.stopPropagation();

        // ensure both are filled
        if(email && username){

          // validate email
          let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
          
          if(reg.test(email)){

            if(username === 'chefnix' && email != 'denisonra@gmail.com'){
              setValMsg('That username is reserved.');
            } else{
              // if everything checks out create the profile
              createProfile();
            }
           
          } else {
            setValMsg('Please enter a valid Email');
          }

        } else {
          setValMsg('Please enter Email and Username');
        }
    };

    const createProfile = async () => {
        // create new profile every time
        // hardcode in the cognito id - not needed right now
        const input = {
          cognitoUserId: "us-east-1:5765febc-1675-4efd-930e-36f2bfdb86cf",
          name: username?.trim().substring(0,7),
          shadowBanned: false,
          email: email,
        };
  
        // post to backend service
        try {
          API.graphql(graphqlOperation(createUserProfile, { input })).then(
            (profileResp) => {
              if (profileResp?.data?.createUserProfile) {

                // set the custom profile based on creating it from API
                setCustomUserProfile(profileResp?.data?.createUserProfile);

                // save profile to local storage
                localStorage.setItem('rally_profile_wEmail', JSON.stringify(profileResp?.data?.createUserProfile));

                // grey out button
                setBtnBackground('#565656');

              }
            }
          );
        } catch (error) {
          console.warn(error);
        }
  
        // close the keyboard automatically
        Keyboard.dismiss();
    }

    const styles = StyleSheet.create({
      container: {
          width: '100%',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 40,
      },
      input: {
          width: '100%',
          padding: 15,
          marginVertical: 10,
          borderWidth: 1,
          borderColor: '#565656',
          borderRadius: 5,
          backgroundColor: '#D6D6D6',
          color: '#1A1A1A',
          fontSize: '2rem',
          outlineColor: '#D6D6D6', // for web
          caretColor: '#1A1A1A', // for web, changes the cursor color
      },
      btn: {
          backgroundColor: btnBackground,
          marginVertical: 15,
          paddingVertical: 20,
          alignItems: 'center',
          borderRadius: 5,
        },
      btnText: {
          fontSize: '2rem',
          color: 'white',
      },
    });

    return (
    <div className='intro-main'>
      {/* slideshow common */}
      {stage === 1 && <div className='stage-1' onClick={() => setStage(2)}></div>}
      {stage === 2 && <div className='stage-2' onClick={() => setStage(3)}></div>}
      {stage === 3 && <div className='stage-3' onClick={() => setStage(4)}></div>}
      {stage === 4 && <div className='stage-4' onClick={() => setStage(5)}></div>}

      {/* slideshow offline */}
      {stage === 5 && !partyLive && (
        <div
          className="stage-OfflineEnd"
          onClick={() => window.location.replace("https://rallychat.app/")}
        ></div>
      )}

      {/* get username */}
      {stage === 5 && partyLive && (
        <div className="stage-OnlineEnd app-not-available">
          <div className="container-fluid d-flex flex-column loginCenter">
            <View style={styles.container}>
              <TextInput
                  style={styles.input}
                  placeholder="email"
                  value={email}
                  keyboardType="email-address"
                  onChangeText={setEmail}
              />
              <TextInput
                  style={styles.input}
                  placeholder="nickname"
                  maxLength="7"
                  value={username}
                  onChangeText={setUserName}
              />
              <TouchableOpacity style={styles.btn} onPress={handleSubmit}>
                  <Text style={styles.btnText}>Join Chat</Text>
              </TouchableOpacity>
            </View>
            {valMsg && (
                <div className='login-vMsg'>{valMsg}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default IntroMain;