import React, { useState } from 'react';
import { View, TextInput, TouchableOpacity, Text, StyleSheet } from 'react-native';
import './RewardMain.css';
import { updateUserProfile } from '../../../graphql/mutations';
import { API, graphqlOperation } from '@aws-amplify/api';
import {Analytics} from 'aws-amplify';

function RewardMain({userProfile, rewardType, setAppStage}) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [validationMsg, setValidationMsg] = useState('');
    const [btnBackground, setBtnBackground] = useState('#E6A400');


    const handleSubmit = async () => {
        if (!firstName || !lastName || !email) {

            // validation message
            setValidationMsg('Please complete Name and Email fields.');
        } else {

            // update the profile with name and contact info
            const input = {
                id: userProfile.id,
                fullName: firstName + ' ' + lastName,
                // email: email,
                phone: phone,
            };
        
            // post to backend service
            try {
                await API.graphql(graphqlOperation(updateUserProfile, { input }));

                // show success
                setValidationMsg('Got it! Head to the DJ table claim your prize.');
                setBtnBackground('#565656');
            } catch (error) {
                console.warn(error);
            }

            // log
            Analytics.record({ name: 'leadIn', attributes: { user: userProfile.id}});
        }
    };

    const styles = StyleSheet.create({
        container: {
            width: '100%',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 40,
        },
        input: {
            width: '100%',
            padding: 15,
            marginVertical: 10,
            borderWidth: 1,
            borderColor: '#565656',
            borderRadius: 5,
            backgroundColor: '#D6D6D6',
            color: '#1A1A1A',
            fontSize: '17px',
            outlineColor: '#D6D6D6', // for web
            caretColor: '#1A1A1A', // for web, changes the cursor color
        },
        btn: {
            backgroundColor: btnBackground,
            marginVertical: 15,
            paddingVertical: 20,
            alignItems: 'center',
            borderRadius: 5,
          },
        btnText: {
            fontSize: 'large',
            color: 'white',
        },
      });

    return (
        <div className='rewMain'>
            
            <div className='rm-header'>{rewardType.header}</div>
            <div className='gout-body'>{rewardType.body}</div>

            {rewardType.showLink && (
                <div>
                    <a href={rewardType.url} target="_blank" rel="noreferrer" onClick={() => Analytics.record({ name: 'linkOut', attributes: { user: userProfile.id, ad: rewardType.logo}})}>
                    <img src={`reward/${rewardType.logo}.png`} className='img-fluid gint-logo' alt="card logo" />
                </a>
                <div className='gint-ClickPlz'>&#8593; Click to view &#8593;</div>
                </div>
            )}

            {rewardType.showForm && (
                <View style={styles.container}>
                    <TextInput
                        style={styles.input}
                        placeholder="First Name"
                        value={firstName}
                        onChangeText={setFirstName}
                    />
                    <TextInput
                        style={styles.input}
                        placeholder="Last Name"
                        value={lastName}
                        onChangeText={setLastName}
                    />
                    {/* <TextInput
                        style={styles.input}
                        placeholder="Email"
                        value={email}
                        keyboardType="email-address"
                        onChangeText={setEmail}
                    /> */}
                    <TextInput
                        style={styles.input}
                        placeholder="Phone"
                        value={phone}
                        keyboardType="phone-pad"
                        onChangeText={setPhone}
                    />

                    <TouchableOpacity style={styles.btn} onPress={handleSubmit}>
                        <Text style={styles.btnText}>Claim</Text>
                    </TouchableOpacity>
                    
                </View>
            )}

            {validationMsg && (
                <div className='rm-vMsg'>{validationMsg}</div>
            )}
            
            <button onClick={() => setAppStage('chat')}>back to chat</button>
        </div>
    );

    
}
export default RewardMain;