import React from 'react';
import './MemoryCard.css';

function MemoryCard({cardInfo, sponsor, setCardStateChange}) {

    // request back to MemoryGame to flip the card
    const handleFlip = () => {

        // don't send click if this is already flipped
        if(!cardInfo.flipped){
            setCardStateChange((prevList) => [...prevList, cardInfo]);
        }
    };

    return (
        <div className={`card ${(cardInfo.flipped ? 'c-flip-yes' : 'c-flip-no')}`}>
            <img 
                src={`memory/${sponsor}/${(cardInfo.flipped ? cardInfo.imgName : sponsor)}.png`} 
                className='img-fluid'
                alt="card logo"
                onClick={handleFlip}/>   
        </div>
    );
}
export default MemoryCard;