import React, { useEffect, useState } from 'react';
import './GameIntro.css';

function GameIntro({setGameStage, gameType}) {

    const [displayCt, setDisplayCt] = useState(30);

    useEffect(() => {
        const countdown = setTimeout(() => {

            // decrements the count, or closes this game stage
            if(displayCt > 0){
                setDisplayCt((x) => x-1);
            } else {
                setGameStage(2);
            }
        }, 1000);

        return (() => clearTimeout(countdown));
    }, [displayCt])

    return (
        <div className='gint'>
            <div className='gint-header'>Get Ready for Memory Match!</div>
            <div className='gint-timer'>{displayCt}</div>
            <div className='gint-sponsor'>Promoting {gameType?.sponsorFull}</div>
            <img src={`memory/${gameType?.sponsor}/${gameType?.sponsor}.png`} className='img-fluid gint-logo' alt="card logo"/>   
        </div>
    );
}
export default GameIntro;