import React from 'react';
import './GameOutro.css';
import {Analytics} from 'aws-amplify';

function GameOutro({gameType, setGameStage}) {

    return (
        <div className='gout'>
            <div className='gout-header'>That Was Fast!</div>
            <div className='gout-body'>Winners announced soon.</div>
            <hr width="100%" color="white"/>
            <div className='gint-sponsor'>To see more by {gameType?.sponsorFull}, click the logo</div>
            <a href={gameType?.url} target="_blank" rel="noreferrer" onClick={() => Analytics.record({ name: 'linkOut', attributes: {ad: gameType?.sponsor}})}>
                <img src={`memory/${gameType?.sponsor}/${gameType?.sponsor}.png`} className='img-fluid gint-logo' alt="card logo" />
            </a>
            <div className='gint-ClickPlz'>&#8593; Click to view &#8593;</div>
            <button onClick={() => setGameStage(4)}>back to chat</button>
        </div>
    );
}
export default GameOutro;