import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import PostTopMessage from './components/admin/PostTopMessag';
import MessageScroller from './components/admin/MessageScroller';
import MetricsReport from './components/admin/MetricsReport'

Amplify.configure(awsconfig);

function Admin() {

    return (      
        <div className="m-3">
            <h2>The Haps</h2>
            <form>
                <PostTopMessage />
                <MetricsReport />
                {/* <MessageScroller /> */}
            </form>
        </div>
    );
}
export default withAuthenticator(Admin);