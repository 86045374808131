import React, { useState } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';

// import './MetricsReport.css';


function MetricsReport() {

    const [topMgs, setTopMsgs] = useState([]);
    const [worstMgs, setWorstMgs] = useState([]);
    const [topFans, setTopFans] = useState([]);
    const [worstFans, setWorstFans] = useState([]);

    const getMetrics = async(event) => {

        // don't refresh page
        event.preventDefault();
        event.stopPropagation();

        // custom query for getting all messages with reactions+type
        console.log('starting recursive calls');
        
        const sample = `[
            {
              "body": "beans beans the magic fruit",
              "type": "USER",
              "userProfile": { "name": "steve" },
              "reactions": {
                "items": [
                  { "type": "UP" },
                  { "type": "UP" },
                  { "type": "UP" },
                  { "type": "UP" },
                  { "type": "UP" },
                  { "type": "DOWN" },
                  { "type": "DOWN" }
                ],
                "nextToken": null
              }
            },
            {
              "body": "the more you eat the more you toot",
              "type": "USER",
              "userProfile": { "name": "steve" },
              "reactions": { "items": [
                  { "type": "UP" },
                  { "type": "UP" },
                  { "type": "UP" },
                  { "type": "DOWN" }
              ], "nextToken": null }
            },
            {
              "body": "the more you toot the better you feel",
              "type": "USER",
              "userProfile": { "name": "steve" },
              "reactions": { "items": [
                  { "type": "UP" }
              ], "nextToken": null }
            },
            {
              "body": "get jiggy with it",
              "type": "USER",
              "userProfile": { "name": "Sam" },
              "reactions": { "items": [
                  { "type": "UP" },
                  { "type": "UP" },
                  { "type": "DOWN" }
              ], "nextToken": null }
            },
            {
              "body": "@Sam bet",
              "type": "USER",
              "userProfile": { "name": "Frank" },
              "reactions": { "items": [], "nextToken": null }
            },
            {
              "body": "crap",
              "type": "USER",
              "userProfile": { "name": "Sam" },
              "reactions": {
                "items": [
                  { "type": "DOWN" }, 
                  { "type": "DOWN" },  
                  { "type": "DOWN" },  
                  { "type": "DOWN" },  
                  { "type": "DOWN" }, 
                  { "type": "UP" }],
                "nextToken": null
              }
            },
            {
              "body": "@Sam nope",
              "type": "USER",
              "userProfile": { "name": "Frank" },
              "reactions": { "items": [{ "type": "UP" }], "nextToken": null }
            }
          ]
          `;
        paginatedCall(false, false, []);
        
        // const fullMetrics = extractMetrics(JSON.parse(sample));
        // getTopMsgs(fullMetrics);
        // getWorstMsgs(fullMetrics);
        // getTopFans(fullMetrics);
        // getWorstFans(fullMetrics);
    }

    const paginatedCall = (nextToken, stopWhenPossible, allResults) => {

        let tokenFilter = (nextToken ? `, nextToken: "${nextToken}"` :'');

        let fullQuery = `query MyQuery {
            listMessages(filter: {type: {eq: USER}, createdAt: {gt: "2024-03-02T12:00:00.000Z"}}${tokenFilter}) {
              items {
                body
                type
                userProfile {
                  name
                }
                reactions {
                  items {
                    type
                  }
                  nextToken
                }
              }
              nextToken
            }
          }`;

          API.graphql(graphqlOperation(fullQuery)).then((resp) => {

            // add any new ones to all results
            allResults.push(...resp?.data?.listMessages?.items);

            // recurse again
            if(resp?.data?.listMessages?.nextToken){
                paginatedCall(resp?.data?.listMessages?.nextToken, true, allResults);
            } else if(stopWhenPossible){

                // stop if no more nextToken is found
                console.log('end of recursion');
                console.log('found records: '+allResults.length);

                const fullMetrics = extractMetrics(allResults);
                getTopMsgs(fullMetrics);
                getWorstMsgs(fullMetrics);
                getTopFans(fullMetrics);
                getWorstFans(fullMetrics);
            }
        });
    }

    // parse json body to pull metrics
    const extractMetrics = (items) => {

        // loop throug each msg
        items.forEach(msg => {

            // calculate score based on reactions
            // default to zero
            msg.score = 0;
            msg.boosted = 0;
            msg.benched = 0;
            
            // if there are reactions, use them instead
            if(msg.reactions.items.length === 1) {

                // just calcualte from the single reaction
                msg.boosted = (msg.reactions.items[0].type === 'UP' ? 1 : 0);
                msg.benched = (msg.reactions.items[0].type === 'DOWN' ? 1 : 0);
                msg.score = msg.boosted - msg.benched;

            }else if(msg.reactions.items.length > 1) {
                
                // map up / down to numeric value
                msg.boosted = msg.reactions.items.reduce((tS,cS) => 
                    (typeof tS === 'number' ?  tS : (tS.type === 'UP' ? 1 : 0)) + (cS.type === 'UP' ? 1 : 0)
                );
                msg.benched = msg.reactions.items.reduce((tS,cS) => 
                    (typeof tS === 'number' ?  tS : (tS.type === 'DOWN' ? 1 : 0)) + (cS.type === 'DOWN' ? 1 : 0)
                );
                
                msg.score = msg.boosted - msg.benched;
            }
        });

        // return same array with added score info
        return items;
    }

    const getTopMsgs = (items) => {

        items = items.sort((a,b) => (a.score < b.score ? 1 : -1));

        let sample = [];

        for (let i = 0; i < items.length && i < 10; i++) {
            sample.push({
                id:'mt'+i, text: items[i].score + 
                ' - ' + items[i].userProfile.name + 
                ' - ' + items[i].body + 
                ' - ' + items[i].boosted + '/'+ items[i].benched})
        }

        setTopMsgs(sample);
    }

    const getWorstMsgs = (items) => {

        items = items.sort((a,b) => (a.score > b.score ? 1 : -1));

        let sample = [];

        for (let i = 0; i < items.length && i < 10; i++) {
            sample.push({
                id:'mt'+i, text: items[i].score + 
                ' - ' + items[i].userProfile.name + 
                ' - ' + items[i].body + 
                ' - ' + items[i].boosted + '/'+ items[i].benched})
        }

        setWorstMgs(sample);
    }

    const getTopFans = (items) => {

        const compiledScores = items.reduce(calculateCompiledScore, {});
        const compiledScoresArray = Object.entries(compiledScores).map(([username, score]) => ({ username, score }));

        items = compiledScoresArray.sort((a,b) => (a.score < b.score ? 1 : -1));

        let sample = [];

        for (let i = 0; i < items.length && i < 10; i++) {
            sample.push({
                id:'mt'+i, text: items[i].score + 
                ' - ' + items[i].username});
        }

        setTopFans(sample);
    }

    const getWorstFans = (items) => {

        const compiledScores = items.reduce(calculateCompiledScore, {});
        const compiledScoresArray = Object.entries(compiledScores).map(([username, score]) => ({ username, score }));

        items = compiledScoresArray.sort((a,b) => (a.score > b.score ? 1 : -1));

        let sample = [];

        for (let i = 0; i < items.length && i < 10; i++) {
            sample.push({
                id:'mt'+i, text: items[i].score + 
                ' - ' + items[i].username});
        }

        setWorstFans(sample);
    }

    const calculateCompiledScore = (accumulator, item) => {
        const { name } = item.userProfile;
        const { score } = item;
    
        accumulator[name] = (accumulator[name] || 0) + score;
    
        return accumulator;
    };

    return (
        <div className="form-group mt-3">
            <label>Run Message Report</label>
            &nbsp;
            <button className="btn btn-light" onClick={getMetrics}>Go</button>
            <h4>Boosted Messages</h4>
            {topMgs.map((mt) => (
                <div key={mt.id}>&#183; {mt.text}</div>
            ))}
            <h4>Benched Messages</h4>
            {worstMgs.map((mt) => (
                <div key={mt.id}>&#183; {mt.text}</div>
            ))}
            <h4>Boosted Fans</h4>
            {topFans.map((mt) => (
                <div key={mt.id}>&#183; {mt.text}</div>
            ))}
            <h4>Benched Fans</h4>
            {worstFans.map((mt) => (
                <div key={mt.id}>&#183; {mt.text}</div>
            ))}
        </div>
    );
}
export default MetricsReport;