import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { onCreateMessage, onCreateReaction } from '../../../../graphql/subscriptions';

import { motion, AnimatePresence } from 'framer-motion';
import ProfileMetrics from '../ProfileMetrics';
import './MessageTop.css';


function MessageTop({userProfile}) {

    const [fontClass, setfontClass] = useState(null);
    const [dispMessage, setDispMessage] = useState(null);
    const [topMessage, setTopMessage] = useState(null);
    const [topMsgTimeout, setTopMsgTimeout] = useState(15);
    const [topMsgType, setTopMsgTyp] = useState('USER');
    const [votes, setVotes] = useState([]);

    // use for setting up listeners
    useEffect(() => {

        const subMessages = API
            .graphql(graphqlOperation(onCreateMessage))
            .subscribe({
                next: (event) => {
                    const msgData = event.value.data.onCreateMessage;

                    // can't figure out server-side filtering syntax - do that later
                    // can't do OR for type
                    // we can filter by channel when that become appropriate
                    // could also do multiple subscriptions
                    if((msgData.type === 'USER' && msgData.userProfile.id === userProfile.id) || msgData.type === 'ADMIN'){
                        
                        setTopMsgTimeout((msgData.type === 'ADMIN' ? 60 : 30));
                        setTopMessage(msgData);
                        setTopMsgTyp(msgData.type);
                    }
                }
        });

        return () => {
            subMessages.unsubscribe();
        };
    }, [userProfile])

    // scaling and truncating initial message text
    useEffect(() => {

        if(topMessage){

            // reference array - order is important
            const classMapping = [{l:30,f:'fs-6'},{l:10,f:'fs-4'},{l:0,f:'fs-2'}];

            // truncate the string
            setDispMessage(topMessage.body.substring(0,50));

            // map to correct font size
            setfontClass(classMapping.find(x => x.l <= topMessage.body.length).f);

            // set up voting listener for your own comment
            if(topMessage.userProfile.id === userProfile.id) setVotes([]);
        
            // set timeout
            const confirmationClear = setTimeout(() => {

                // clear out top message
                setDispMessage('');

            }, topMsgTimeout*1000); // show for X seconds - better logic comming later

            return (() => clearTimeout(confirmationClear));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topMessage])

    // set up voting listener
    useEffect(() => {
        // listen to all incoming reactions
        const subReactions = API
        .graphql(graphqlOperation(onCreateReaction))
        .subscribe({
            next: (event) => {
                const msgData = event.value.data.onCreateReaction;

                // was this a reaction for the current message
                if(topMessage && msgData?.messageReactionsId === topMessage.id){

                    // feed into logic to display on the UI
                    setVotes([...votes, msgData.type]);
                }
            }
        });

        return () => {
            subReactions.unsubscribe();
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [votes])

    return (
        <div>
            <ProfileMetrics userProfile={userProfile}/>
            {/* <div className='hostLine'>Host Ed Nixon <br/>@chefnix</div> */}
            <AnimatePresence>
                
                {dispMessage &&
                    <motion.div 
                        initial ={{ scale: [0,0] }}
                        animate={{ scale: [0,1] }}
                        exit={{ scale: [1,0] }}
                        transition={{ duration: .2,  type: 'linear'}}
                        className={fontClass+' app-message-top'}>
                        <div>
                            {dispMessage}
                        </div>
                        {topMsgType === 'USER' &&
                            <div>
                                <div className='app-message-top-up'>
                                    &#9650; {votes.filter(x => x === 'UP').length}
                                </div>
                                <div className='app-message-top-down'>
                                    &#9660; {votes.filter(x => x === 'DOWN').length}
                                </div>
                            </div>
                        }   
                    </motion.div>  
                }
            </AnimatePresence>
        </div>
    );
}
export default MessageTop;