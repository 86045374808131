import React from 'react';
import './MsgPopup.css';

function MsgPopup({text, onClose}) {

    return (
        <div className="popup-container">
            <div className="popup-body">
                {text}
                <div className='popup-body-btn' onClick={onClose}>X</div>
            </div>
        </div>
    );
}
export default MsgPopup;