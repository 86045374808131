import React, { useEffect, useState } from 'react';
import './GameMain.css';
import GameIntro from './GameIntro';
import MemoryGame from './MemoryGame';
import GameOutro from './GameOutro';

function GameMain({userProfile, gameType, setAppStage}) {
    
    // setting for stepping through the app instructions
    const [gameStage, setGameStage] = useState(1);

    // close the entire game once all stages have progressed
    useEffect(() => {
        if(gameStage > 3){
            setAppStage('chat');
        }
    }, [gameStage])

    return (
        // controls the different stages
        <div className='game-main'>
            {gameStage === 1 && <GameIntro gameType={gameType} setGameStage={setGameStage}/>}
            {gameStage === 2 && <MemoryGame gameType={gameType} userProfile={userProfile} setGameStage={setGameStage}/>}
            {gameStage === 3 && <GameOutro gameType={gameType} setGameStage={setGameStage}/>}
        </div>
    );
}
export default GameMain;