import React, { useEffect, useState } from 'react';

import { API, graphqlOperation } from '@aws-amplify/api';
import { onCreateEmote } from '../../../graphql/subscriptions';

import MessageTop from './messaging/MessageTop';
import EmojiFloat from './reactions/EmojiFloat';

import './HighlightWindow.css';

function HighlightWindow({userProfile, autoEmotes}) {

    const [floatEmojis, setFloatEmojis] = useState([]);
    const [indexInc, setIndexInc] = useState(0);
    const [randEmoteInc, setRandEmoteInc] = useState(0);
    const [currentEmoji, setCurrentEmoji] = useState({id: '+1', skinTone: '1'});

    // use for setting up listeners
    useEffect(() => {

        const subReactions = API
            .graphql(graphqlOperation(onCreateEmote))
            .subscribe({
                next: (event) => {

                    // set which emoji is about to appear
                    setCurrentEmoji({
                        id: event.value.data.onCreateEmote.emojiId,
                        skinTone: event.value.data.onCreateEmote.skinTone,
                    });

                    // trigger index increment - gets picked up by useEffect to create new float
                    setIndexInc((ct) => ct+1);
                }
        });

        return () => {
            subReactions.unsubscribe();
        };
    
        // document.addEventListener('emoji-selection', handleEmojiSelection);
        // document.addEventListener('user-message', handleUserMessage);

        // return () => {
        //     document.removeEventListener('emoji-selection', handleEmojiSelection);
        //     document.removeEventListener('user-message', handleUserMessage);
        // }

    }, [])

    // called when auto emotes change
    useEffect(() => {
        scheduleRandomEmote();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoEmotes])

    // called next time we want to auto-generate an emote
    useEffect(() => {
        scheduleRandomEmote();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [randEmoteInc])

    // gets called anytime new emojis come in
    useEffect(() => {

        // simple mechanism for purging old floats
         let tempArr = floatEmojis;
         if(tempArr[0]){
             tempArr = tempArr.filter(x => x.index > indexInc - 10);
         }

         // create new float
         setFloatEmojis([...tempArr, {index: indexInc, inner:currentEmoji, xStart: getRandomInt(1,85), createdDate: new Date().getSeconds().toString()}]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexInc])

    const scheduleRandomEmote = () => {

        // if settings exist
        if(autoEmotes){

            // set timeout for next emote to occur
            const foo = setTimeout(() => {

                // configure emote
                setCurrentEmoji({
                    id: autoEmotes[getRandomInt(0,autoEmotes.length)], // pick random from array
                    skinTone: 1,
                });

                // trigger index increment - gets picked up by useEffect to create new float
                setIndexInc((ct) => ct+1);

                // will lanch the next random emote
                setRandEmoteInc((ct) => ct+1);

            // random time generator
            }, (getRandomInt(1,40) * 100));
    
            return (() => clearTimeout(foo));
        }
    }

    // random number generator
    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    }

    return (
        <div className='app-top-pane'>
            <MessageTop userProfile={userProfile}/>
            {floatEmojis.map((float) => (
                <EmojiFloat key={float.index} content={float.inner} xPosition={float.xStart}/>
            ))}
        </div>
    );
}
export default HighlightWindow;