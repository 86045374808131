/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      channel
      body
      unmaskedBody
      type
      reactions {
        items {
          id
          type
          messageUserProfileId
          createdAt
          updatedAt
          messageReactionsId
          reactionUserProfileId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sentimentScore
      relevancyScore
      politicalDiscourse
      sexualContent
      racismSexismHomophobia
      violentSpeech
      maskedContent
      updatedAt
      messageUserProfileId
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        channel
        body
        unmaskedBody
        type
        reactions {
          nextToken
          __typename
        }
        createdAt
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        sentimentScore
        relevancyScore
        politicalDiscourse
        sexualContent
        racismSexismHomophobia
        violentSpeech
        maskedContent
        updatedAt
        messageUserProfileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmote = /* GraphQL */ `
  query GetEmote($id: ID!) {
    getEmote(id: $id) {
      id
      channel
      emojiId
      skinTone
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      emoteUserProfileId
      __typename
    }
  }
`;
export const listEmotes = /* GraphQL */ `
  query ListEmotes(
    $filter: ModelEmoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        channel
        emojiId
        skinTone
        createdAt
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        emoteUserProfileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReaction = /* GraphQL */ `
  query GetReaction($id: ID!) {
    getReaction(id: $id) {
      id
      message {
        id
        channel
        body
        unmaskedBody
        type
        reactions {
          nextToken
          __typename
        }
        createdAt
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        sentimentScore
        relevancyScore
        politicalDiscourse
        sexualContent
        racismSexismHomophobia
        violentSpeech
        maskedContent
        updatedAt
        messageUserProfileId
        __typename
      }
      type
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      messageUserProfileId
      createdAt
      updatedAt
      messageReactionsId
      reactionUserProfileId
      __typename
    }
  }
`;
export const listReactions = /* GraphQL */ `
  query ListReactions(
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message {
          id
          channel
          body
          unmaskedBody
          type
          createdAt
          sentimentScore
          relevancyScore
          politicalDiscourse
          sexualContent
          racismSexismHomophobia
          violentSpeech
          maskedContent
          updatedAt
          messageUserProfileId
          __typename
        }
        type
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        messageUserProfileId
        createdAt
        updatedAt
        messageReactionsId
        reactionUserProfileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDynamicSettings = /* GraphQL */ `
  query GetDynamicSettings($id: ID!) {
    getDynamicSettings(id: $id) {
      env
      msgFrequency
      partyLive
      emotes
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDynamicSettings = /* GraphQL */ `
  query ListDynamicSettings(
    $filter: ModelDynamicSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDynamicSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        env
        msgFrequency
        partyLive
        emotes
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChannelAlert = /* GraphQL */ `
  query GetChannelAlert($id: ID!) {
    getChannelAlert(id: $id) {
      id
      channel
      type
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChannelAlerts = /* GraphQL */ `
  query ListChannelAlerts(
    $filter: ModelChannelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannelAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        channel
        type
        detail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      cognitoUserId
      name
      fullName
      email
      phone
      shadowBanned
      newRamsRewardsLead
      fanMakerId
      friends {
        items {
          id
          createdAt
          updatedAt
          userProfileFriendsId
          userFriendUserId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserFriend = /* GraphQL */ `
  query GetUserFriend($id: ID!) {
    getUserFriend(id: $id) {
      id
      user {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userProfileFriendsId
      userFriendUserId
      __typename
    }
  }
`;
export const listUserFriends = /* GraphQL */ `
  query ListUserFriends(
    $filter: ModelUserFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        userProfileFriendsId
        userFriendUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIntegrationError = /* GraphQL */ `
  query GetIntegrationError($id: ID!) {
    getIntegrationError(id: $id) {
      id
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIntegrationErrors = /* GraphQL */ `
  query ListIntegrationErrors(
    $filter: ModelIntegrationErrorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationErrors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        detail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByChannel = /* GraphQL */ `
  query MessagesByChannel(
    $channel: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChannel(
      channel: $channel
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channel
        body
        unmaskedBody
        type
        reactions {
          nextToken
          __typename
        }
        createdAt
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        sentimentScore
        relevancyScore
        politicalDiscourse
        sexualContent
        racismSexismHomophobia
        violentSpeech
        maskedContent
        updatedAt
        messageUserProfileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const channelAlertsByChannel = /* GraphQL */ `
  query ChannelAlertsByChannel(
    $channel: String!
    $sortDirection: ModelSortDirection
    $filter: ModelChannelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    channelAlertsByChannel(
      channel: $channel
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channel
        type
        detail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const profilesByCognitoId = /* GraphQL */ `
  query ProfilesByCognitoId(
    $cognitoUserId: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByCognitoId(
      cognitoUserId: $cognitoUserId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
