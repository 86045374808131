import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { createMessage } from '../../graphql/mutations';
import { profilesByCognitoId} from '../../graphql/queries';

import { Auth } from '@aws-amplify/auth';

function PostTopMessage() {

    const [userProfile, setUserProfile] = useState(null);
    const [msgType, setMsgType] = useState('ADMIN');

    // get user info 
    useEffect(() => {
        
        // get cognito info from Auth
        Auth.currentUserInfo().then((cognitoUserInfo) => {

            // get full custom profile for user
            API.graphql(graphqlOperation(profilesByCognitoId, {cognitoUserId: cognitoUserInfo.id})).then((response) => {

                // if the custom profile is complete, retrieve it
                if(response?.data?.profilesByCognitoId?.items?.length > 0){
                
                    // get the first one
                    setUserProfile(response.data.profilesByCognitoId.items[0]);
                }
            });
        });
    }, [])

    const handleSubmit = async(event) => {

        if(event.key === 'Enter') {

            event.preventDefault();
            event.stopPropagation();

            const input = {
                channel: '1',
                messageUserProfileId: userProfile.id,
                type: msgType,
                body: event.target.value.trim()
            };

            // post to backend service
            try {
                await API.graphql(graphqlOperation(createMessage, { input }))
            } catch (error) {
                console.warn(error);
            }

            // clear out text box
            event.target.value = '';
        }
    }

    return (
        <div className="form-group">
            <label>Post Message</label>
            <select 
                className="form-control mb-1" 
                value={msgType}
                onChange={e => setMsgType(e.target.value)}>
                <option>ADMIN</option>
                <option>USER</option>
            </select>
            <textarea 
                className="form-control" 
                rows="3"
                onKeyPress={handleSubmit}/>
        </div>
    );
}
export default PostTopMessage;