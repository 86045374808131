/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "53d155a4b89e414e8b5e42a868e7aa6b",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "53d155a4b89e414e8b5e42a868e7aa6b",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "fanMakerYup",
            "endpoint": "https://pxs49p0b0m.execute-api.us-east-1.amazonaws.com/develop",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://bhngsibdozfn7krvjbznl65ooq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nzklj54kz5hlvfozubxxdmtsoe",
    "aws_cognito_identity_pool_id": "us-east-1:591e51a7-17e0-4f34-ac0d-e42e825571ab",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OO26vAP4l",
    "aws_user_pools_web_client_id": "fichqka9fg1iuj01v6p5bqess",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
