/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      channel
      body
      unmaskedBody
      type
      reactions {
        items {
          id
          type
          messageUserProfileId
          createdAt
          updatedAt
          messageReactionsId
          reactionUserProfileId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sentimentScore
      relevancyScore
      politicalDiscourse
      sexualContent
      racismSexismHomophobia
      violentSpeech
      maskedContent
      updatedAt
      messageUserProfileId
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      channel
      body
      unmaskedBody
      type
      reactions {
        items {
          id
          type
          messageUserProfileId
          createdAt
          updatedAt
          messageReactionsId
          reactionUserProfileId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sentimentScore
      relevancyScore
      politicalDiscourse
      sexualContent
      racismSexismHomophobia
      violentSpeech
      maskedContent
      updatedAt
      messageUserProfileId
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      channel
      body
      unmaskedBody
      type
      reactions {
        items {
          id
          type
          messageUserProfileId
          createdAt
          updatedAt
          messageReactionsId
          reactionUserProfileId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sentimentScore
      relevancyScore
      politicalDiscourse
      sexualContent
      racismSexismHomophobia
      violentSpeech
      maskedContent
      updatedAt
      messageUserProfileId
      __typename
    }
  }
`;
export const createEmote = /* GraphQL */ `
  mutation CreateEmote(
    $input: CreateEmoteInput!
    $condition: ModelEmoteConditionInput
  ) {
    createEmote(input: $input, condition: $condition) {
      id
      channel
      emojiId
      skinTone
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      emoteUserProfileId
      __typename
    }
  }
`;
export const updateEmote = /* GraphQL */ `
  mutation UpdateEmote(
    $input: UpdateEmoteInput!
    $condition: ModelEmoteConditionInput
  ) {
    updateEmote(input: $input, condition: $condition) {
      id
      channel
      emojiId
      skinTone
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      emoteUserProfileId
      __typename
    }
  }
`;
export const deleteEmote = /* GraphQL */ `
  mutation DeleteEmote(
    $input: DeleteEmoteInput!
    $condition: ModelEmoteConditionInput
  ) {
    deleteEmote(input: $input, condition: $condition) {
      id
      channel
      emojiId
      skinTone
      createdAt
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      emoteUserProfileId
      __typename
    }
  }
`;
export const createReaction = /* GraphQL */ `
  mutation CreateReaction(
    $input: CreateReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    createReaction(input: $input, condition: $condition) {
      id
      message {
        id
        channel
        body
        unmaskedBody
        type
        reactions {
          nextToken
          __typename
        }
        createdAt
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        sentimentScore
        relevancyScore
        politicalDiscourse
        sexualContent
        racismSexismHomophobia
        violentSpeech
        maskedContent
        updatedAt
        messageUserProfileId
        __typename
      }
      type
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      messageUserProfileId
      createdAt
      updatedAt
      messageReactionsId
      reactionUserProfileId
      __typename
    }
  }
`;
export const updateReaction = /* GraphQL */ `
  mutation UpdateReaction(
    $input: UpdateReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    updateReaction(input: $input, condition: $condition) {
      id
      message {
        id
        channel
        body
        unmaskedBody
        type
        reactions {
          nextToken
          __typename
        }
        createdAt
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        sentimentScore
        relevancyScore
        politicalDiscourse
        sexualContent
        racismSexismHomophobia
        violentSpeech
        maskedContent
        updatedAt
        messageUserProfileId
        __typename
      }
      type
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      messageUserProfileId
      createdAt
      updatedAt
      messageReactionsId
      reactionUserProfileId
      __typename
    }
  }
`;
export const deleteReaction = /* GraphQL */ `
  mutation DeleteReaction(
    $input: DeleteReactionInput!
    $condition: ModelReactionConditionInput
  ) {
    deleteReaction(input: $input, condition: $condition) {
      id
      message {
        id
        channel
        body
        unmaskedBody
        type
        reactions {
          nextToken
          __typename
        }
        createdAt
        userProfile {
          id
          cognitoUserId
          name
          fullName
          email
          phone
          shadowBanned
          newRamsRewardsLead
          fanMakerId
          createdAt
          updatedAt
          __typename
        }
        sentimentScore
        relevancyScore
        politicalDiscourse
        sexualContent
        racismSexismHomophobia
        violentSpeech
        maskedContent
        updatedAt
        messageUserProfileId
        __typename
      }
      type
      userProfile {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      messageUserProfileId
      createdAt
      updatedAt
      messageReactionsId
      reactionUserProfileId
      __typename
    }
  }
`;
export const createDynamicSettings = /* GraphQL */ `
  mutation CreateDynamicSettings(
    $input: CreateDynamicSettingsInput!
    $condition: ModelDynamicSettingsConditionInput
  ) {
    createDynamicSettings(input: $input, condition: $condition) {
      env
      msgFrequency
      partyLive
      emotes
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDynamicSettings = /* GraphQL */ `
  mutation UpdateDynamicSettings(
    $input: UpdateDynamicSettingsInput!
    $condition: ModelDynamicSettingsConditionInput
  ) {
    updateDynamicSettings(input: $input, condition: $condition) {
      env
      msgFrequency
      partyLive
      emotes
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDynamicSettings = /* GraphQL */ `
  mutation DeleteDynamicSettings(
    $input: DeleteDynamicSettingsInput!
    $condition: ModelDynamicSettingsConditionInput
  ) {
    deleteDynamicSettings(input: $input, condition: $condition) {
      env
      msgFrequency
      partyLive
      emotes
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChannelAlert = /* GraphQL */ `
  mutation CreateChannelAlert(
    $input: CreateChannelAlertInput!
    $condition: ModelChannelAlertConditionInput
  ) {
    createChannelAlert(input: $input, condition: $condition) {
      id
      channel
      type
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChannelAlert = /* GraphQL */ `
  mutation UpdateChannelAlert(
    $input: UpdateChannelAlertInput!
    $condition: ModelChannelAlertConditionInput
  ) {
    updateChannelAlert(input: $input, condition: $condition) {
      id
      channel
      type
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChannelAlert = /* GraphQL */ `
  mutation DeleteChannelAlert(
    $input: DeleteChannelAlertInput!
    $condition: ModelChannelAlertConditionInput
  ) {
    deleteChannelAlert(input: $input, condition: $condition) {
      id
      channel
      type
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      cognitoUserId
      name
      fullName
      email
      phone
      shadowBanned
      newRamsRewardsLead
      fanMakerId
      friends {
        items {
          id
          createdAt
          updatedAt
          userProfileFriendsId
          userFriendUserId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      cognitoUserId
      name
      fullName
      email
      phone
      shadowBanned
      newRamsRewardsLead
      fanMakerId
      friends {
        items {
          id
          createdAt
          updatedAt
          userProfileFriendsId
          userFriendUserId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      cognitoUserId
      name
      fullName
      email
      phone
      shadowBanned
      newRamsRewardsLead
      fanMakerId
      friends {
        items {
          id
          createdAt
          updatedAt
          userProfileFriendsId
          userFriendUserId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserFriend = /* GraphQL */ `
  mutation CreateUserFriend(
    $input: CreateUserFriendInput!
    $condition: ModelUserFriendConditionInput
  ) {
    createUserFriend(input: $input, condition: $condition) {
      id
      user {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userProfileFriendsId
      userFriendUserId
      __typename
    }
  }
`;
export const updateUserFriend = /* GraphQL */ `
  mutation UpdateUserFriend(
    $input: UpdateUserFriendInput!
    $condition: ModelUserFriendConditionInput
  ) {
    updateUserFriend(input: $input, condition: $condition) {
      id
      user {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userProfileFriendsId
      userFriendUserId
      __typename
    }
  }
`;
export const deleteUserFriend = /* GraphQL */ `
  mutation DeleteUserFriend(
    $input: DeleteUserFriendInput!
    $condition: ModelUserFriendConditionInput
  ) {
    deleteUserFriend(input: $input, condition: $condition) {
      id
      user {
        id
        cognitoUserId
        name
        fullName
        email
        phone
        shadowBanned
        newRamsRewardsLead
        fanMakerId
        friends {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userProfileFriendsId
      userFriendUserId
      __typename
    }
  }
`;
export const createIntegrationError = /* GraphQL */ `
  mutation CreateIntegrationError(
    $input: CreateIntegrationErrorInput!
    $condition: ModelIntegrationErrorConditionInput
  ) {
    createIntegrationError(input: $input, condition: $condition) {
      id
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIntegrationError = /* GraphQL */ `
  mutation UpdateIntegrationError(
    $input: UpdateIntegrationErrorInput!
    $condition: ModelIntegrationErrorConditionInput
  ) {
    updateIntegrationError(input: $input, condition: $condition) {
      id
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIntegrationError = /* GraphQL */ `
  mutation DeleteIntegrationError(
    $input: DeleteIntegrationErrorInput!
    $condition: ModelIntegrationErrorConditionInput
  ) {
    deleteIntegrationError(input: $input, condition: $condition) {
      id
      detail
      createdAt
      updatedAt
      __typename
    }
  }
`;
